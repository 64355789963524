// Components:
import { Button, Heading, Icon, Paragraph, TextArea } from 'atoms';
import { SettingsDialog, ShareDialog } from 'components';
import { LoadingSpinner } from 'components/LoadingSpinner';
// Context:
import { AuthContext, EditorContext, LangContext, useUser } from 'context';
// Languages
import Localizations from 'languages';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
// Types
import { SavedConfiguration } from 'types';
// Utils
import { isTranslationKey, post } from 'utils';
// Content:
import { Break, Static } from 'languages';

// ==============================================================
interface Props {
  savedConfiguration: SavedConfiguration;
  configIsOpen: boolean;
  navHeight: number;
}

interface CalculatedDevice {
  count: number;
  code: string;
  description: string;
  price: string;
}

interface SubData {
  id: string;
  product: CalculatedDevice[];
  totalPrice: string;
  type: 'FlexiChef' | 'Masterline' | 'MarineMeister' | 'Modular' | 'SpaceCombi';
}

// ==============================================================
export const HeaderContent: FC<Props> = ({ savedConfiguration, configIsOpen }) => {
  const { configuration } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const { userRights } = useContext(AuthContext);
  const {
    hasSurchargeMasterlineAccess,
    hasPriceAccess,
    hasSinglePriceAccess,
    hasFlexiSinglePriceAccess,
    hasMarineMeisterSinglePriceAccess,
    hasMasterlineSinglePriceAccess,
    hasModularSinglePriceAccess,
    hasModularNOLSinglePriceAccess,
    hasSpaceSinglePriceAccess,
    hasMasterlineTotalPriceAccess,
    hasModularTotalPriceAccess,
    hasModularNOLTotalPriceAccess,
    hasMarineMeisterTotalPriceAccess
  } = useUser();
  const [intro, setIntro] = useState<string>(configuration ? configuration.getIntro() : Localizations['pdfIntro'][lang]);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showPrices, setShowPrices] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [subDatas, setSubDatas] = useState<SubData[]>([]);
  const [wentWrong, setWentWrong] = useState(false);

  const calculatePrices = async () => {
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/calculate/configuration`, {
      data: {
        language: lang,
        json: configuration.exportJSON()
      }
    });
    if (data) {
      setSubDatas(data.subData);
      setTotalPrice(data.totalPrice);
      setWentWrong(false);
      setLoading(false);
    }
    if (error) {
      console.log(error);
      setWentWrong(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (configuration) {
      calculatePrices();
    }
  }, [configuration, lang, /* showDialog, */ configIsOpen, showSettings]);

  useEffect(() => {
    if (!configIsOpen && showDialog) setShowDialog(false);
  }, [configIsOpen, showDialog]);

  useEffect(() => {
    if (configuration) {
      configuration.setIntro(intro);
    }
  }, [configuration, intro, configIsOpen]);

  useEffect(() => {
    if (!(intro !== Localizations['pdfIntro'].de && intro !== Localizations['pdfIntro'].en && intro !== Localizations['pdfIntro'].cz)) {
      setIntro(Localizations['pdfIntro'][lang]);
    }
  }, [lang]);

  const canUserSeeCategorySinglePrices = (type: string) => {
    if (type === 'Masterline') return hasMasterlineSinglePriceAccess;
    if (type === 'Modular') return hasModularSinglePriceAccess;
    if (type === 'ModularNOL') return hasModularNOLSinglePriceAccess;
    if (type === 'SpaceCombi') return hasSpaceSinglePriceAccess;
    if (type === 'FlexiChef') return hasFlexiSinglePriceAccess;
    if (type === 'MarineMeister') return hasMarineMeisterSinglePriceAccess;
    return false;
  };

  const canUserSeeCategoryTotalPrice = (type: string) => {
    if (type === 'Masterline') return hasMasterlineTotalPriceAccess;
    if (type === 'Modular') return hasModularTotalPriceAccess;
    if (type === 'ModularNOL') return hasModularNOLTotalPriceAccess;
    if (type === 'SpaceCombi') return true;
    if (type === 'FlexiChef') return true;
    if (type === 'MarineMeister') return hasMarineMeisterTotalPriceAccess;
    return false;
  };

  const firstMasterlineIndex = subDatas?.findIndex(s => s.type === 'Masterline');

  return (
    <Fragment>
      {/* Header Content */}
      {savedConfiguration && (
        <div className={`Header-Content ${configIsOpen ? 'Header-Content-Expanded' : 'Header-Content-Collapsed'}`}>
          {/* Main */}
          <div className={`Header-Content-Main ${configIsOpen ? '' : 'Faded-Out'}`}>
            <div className="Header-Content-Main-Static">
              <Heading level="1" fontSize="1.75rem" color="dark" fontWeight="bold" margin="2rem 0 0.25rem">
                {savedConfiguration.customer}
              </Heading>
              <Paragraph fontSize="1.25rem" margin="0 0 3rem">
                {savedConfiguration.name} - {savedConfiguration.location}
              </Paragraph>

              <TextArea height="10rem" setValue={e => setIntro(e)} value={intro} />
              <br />
              {/* <Paragraph color="signal" fontWeight={'bold'} fontSize="1.25rem" margin="2rem 0 3rem">
                {Localizations['offerHint'][lang]}
              </Paragraph> */}
            </div>
            {loading && <LoadingSpinner color="medium" />}

            {!wentWrong &&
              subDatas &&
              Array.isArray(subDatas) &&
              subDatas.map((subData, i) => {
                const isFirstMasterline = i === firstMasterlineIndex;
                const isMasterline = subData.type === 'Masterline';

                if (subData && subData.product && subData.product.length > 0) {
                  const canUserSeeSinglePrice = canUserSeeCategorySinglePrices(subData.type);
                  return (
                    <div className="Header-Content-Main-DeviceTable" key={`${subData.id} - Table`}>
                      {!loading &&
                        isFirstMasterline &&
                        Static.map((p: any, index: number) => (
                          <Paragraph key={'P-' + index} fontSize="1" margin="0 0 2rem">
                            {p[lang]}
                          </Paragraph>
                        ))}
                      {!loading && isFirstMasterline && <Paragraph>{Break[lang]}</Paragraph>}
                      {isMasterline && <Paragraph margin="1rem 0">{Localizations['installationWallHint'][lang]}</Paragraph>}

                      <table>
                        <thead>
                          <tr>
                            <th className="Row-L">{Localizations['type'][lang]}</th>
                            <th className="Row-Code">{Localizations['mknCode'][lang]}</th>
                            <th className="Row-Description">{Localizations['article'][lang]}</th>
                            <th className="Row-Empty">{Localizations['empty'][lang]}</th>
                            <th className="Row-Amount">{Localizations['amount'][lang]}</th>
                            {showPrices && <th className="Row-Price">{Localizations['price'][lang]} (eur)</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            subData.product.map((pos: CalculatedDevice, index: number) => {
                              const { count, code, description, price } = pos;
                              const isMarineMeister = subData.type === 'MarineMeister';
                              return (
                                <tr key={`row-${code}-${index}`} className={`Content-Row${index % 2 === 1 ? '-Bg' : ''}`}>
                                  <td>L</td>
                                  <td style={{ wordBreak: 'break-all' }}>{isMarineMeister ? '-' : code}</td>
                                  <td>
                                    <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: description }}></div>
                                  </td>
                                  <td>&nbsp;</td>
                                  <td>{count}</td>
                                  {showPrices && (
                                    <td style={{ textAlign: 'right' }}>
                                      <div style={{ paddingRight: '1.75rem' }}>
                                        {isTranslationKey(price)
                                          ? Localizations[price.substring(1, price.length - 1)][lang] ?? `Missing Translation ${price}`
                                          : price}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          {!loading && subData?.totalPrice && showPrices && (userRights.includes('Admin') || canUserSeeCategoryTotalPrice(subData.type)) && (
                            <tr className={`Content-Row${subData.product.length % 2 === 1 ? '-Bg' : ''}`}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: 'right' }}>
                                <div style={{ paddingRight: '1.75rem' }}>
                                  <b>{subData.totalPrice}</b>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  );
                } else return null;
              })}
            {/* {!wentWrong && Array.isArray(subDatas) && subDatas.length > 0 && (
              <div className="Header-Content-Main-DeviceTable" key={`Total - Table`}>
                <table>
                  <tbody>
                    {!loading &&
                      totalPrice &&
                      showPrices &&
                      (hasSinglePriceAccess || userRights.includes('ShowTotalPrice') || userRights.includes('Admin')) && (
                        <tr className={`Content-Row-Bg`}>
                          <td style={{ textAlign: 'right' }}>
                            <div style={{ paddingRight: '1.75rem' }}>
                              <b>{`${Localizations['totalPrice'][lang]}: ${totalPrice}`}</b>
                            </div>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            )} */}

            {wentWrong && (
              <div className="Header-Content-Main-DeviceTable">
                <div> {Localizations['calcError'][lang]}</div>
                <br />
                <Button btnType="fourth" className="mt-2" fontSize=".75rem" padding=".5rem 1rem" onClick={calculatePrices}>
                  {Localizations['calcAgain'][lang]}
                </Button>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={`Header-Content-Footer${configIsOpen ? '' : '-Collapsed'}`}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0 6rem' }}>
              {/* My Configuration */}
              <div className="Header-Content-Footer-Item" style={{ paddingLeft: '1.65rem' }}>
                <span>{Localizations['myConfig'][lang]}</span>
                <span className="Header-Content-Footer-Item-Sub">
                  {savedConfiguration.name} <Icon type="arrowRight" stroke={1} size="0.6rem" color="medium" margin="0 0 0 0.25rem" />
                </span>
              </div>

              {/* Button & Sharing */}
              <div className="Header-Content-Footer-Collection">
                {(hasPriceAccess || hasSurchargeMasterlineAccess) && (
                  <div className="Header-Content-Footer-Item">
                    <Icon
                      type="settings"
                      size="1.75rem"
                      color={configuration ? 'medium' : 'grey'}
                      margin="0 2rem 0 0"
                      /* stroke={2.5} */
                      style={{ cursor: configuration ? 'pointer' : 'not-allowed' }}
                      onClick={() => {
                        if (configuration) {
                          setShowSettings(true);
                        }
                      }}
                    />
                  </div>
                )}
                {userRights &&
                  (hasSinglePriceAccess ||
                    userRights.includes('Admin') ||
                    userRights.includes('ShowTotalPrice') ||
                    userRights.includes('ShowTotalPriceMarineMeister') ||
                    userRights.includes('ShowTotalPriceModular') ||
                    userRights.includes('ShowTotalPriceMasterline')) && (
                    <div className="Header-Content-Footer-Item">
                      {showPrices ? (
                        <Icon onClick={() => setShowPrices(!showPrices)} size="1.75rem" type="pricesOff" />
                      ) : (
                        <Icon onClick={() => setShowPrices(!showPrices)} size="1.75rem" type="pricesOn" />
                      )}
                    </div>
                  )}
                <div className="Header-Content-Footer-Item">
                  <Icon
                    type="share"
                    size="1.5rem"
                    color={savedConfiguration.id ? 'medium' : 'grey'}
                    margin="0 0 0 2rem"
                    stroke={2.5}
                    style={{ cursor: savedConfiguration.id ? 'pointer' : 'not-allowed' }}
                    onClick={() => {
                      if (savedConfiguration.id) {
                        setShowDialog(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {configuration && <SettingsDialog close={() => setShowSettings(false)} open={configIsOpen && showSettings} />}
      {configuration && <ShareDialog close={() => setShowDialog(false)} open={configIsOpen && showDialog} showPrices={showPrices} />}
    </Fragment>
  );
};
