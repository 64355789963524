import React, { FC, Fragment, useContext, useState, useRef, useEffect } from 'react';

// Components
import { Selectbox } from 'atoms';
import { BlockSelector } from 'components';
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// ===================================================================

export const ModularNOLGlobal: FC = () => {
  const { lang } = useContext(LangContext);
  const { selectedModularNOL, setModularNOLView } = useContext(EditorContext);

  const socketOptions = [Localizations['buildSideSocket'][lang], Localizations['installedFeet'][lang]];
  const [socketStyle, setSocketStyle] = useState('');

  const didMount = useRef(false);

  useEffect(() => {
    return () => {
      didMount.current = false;
    };
  }, [selectedModularNOL]);

  // Set Socket Selectboxes initial value to value of current config
  useEffect(() => {
    if (!didMount.current) {
      if (selectedModularNOL && selectedModularNOL.getBottom()) {
        const bottom = selectedModularNOL.getBottom();
        //const bottomHeight = selectedModularNOL.getBottomHeight();
        /* if (bottomHeight === 20) {
          setSocketStyle(Localizations['buildSideSocket200'][lang]);
        } else { */
        if (bottom === 'Base') {
          setSocketStyle(Localizations['buildSideSocket'][lang]);
        } else if (bottom === 'Feet') {
          setSocketStyle(Localizations['installedFeet'][lang]);
        }
        //}
      }
      didMount.current = true;
    } else {
      const bottom = selectedModularNOL.getBottom();
      /* const bottomHeight = selectedModularNOL.getBottomHeight();
      if (bottomHeight === 20) {
        setSocketStyle(Localizations['buildSideSocket200'][lang]);
      } else { */
      if (bottom === 'Base') {
        setSocketStyle(Localizations['buildSideSocket'][lang]);
      } else if (bottom === 'Feet') {
        setSocketStyle(Localizations['installedFeet'][lang]);
      }
      //}
    }
  }, [selectedModularNOL]);

  useEffect(() => {
    if (didMount.current) {
      //const bottomHeight = selectedModularNOL.getBottomHeight();
      if (socketStyle === Localizations['buildSideSocket'][lang]) {
        //if (bottomHeight === 20) selectedModularNOL.setBottomHeight(15);
        if (!(selectedModularNOL.getBottom() === 'Base')) selectedModularNOL.setBottom('Base');
      } else if (socketStyle === Localizations['installedFeet'][lang]) {
        //if (bottomHeight === 20) selectedModularNOL.setBottomHeight(15);
        if (!(selectedModularNOL.getBottom() === 'Feet')) selectedModularNOL.setBottom('Feet');
      } /* else if (socketStyle === Localizations['buildSideSocket200'][lang]) {
        if (bottomHeight === 15) selectedModularNOL.setBottomHeight(20);
        if (!(selectedModularNOL.getBottom() === 'Base')) selectedModularNOL.setBottom('Base');
      } */
    }
  }, [socketStyle]);

  return (
    <Fragment>
      <ContextMenu mode="back" noMargin onClick={() => setModularNOLView('home')}></ContextMenu>
      {/* <div style={{ marginLeft: '.5rem' }}>
        <div className="Submenu-Headline">
          <span>{Localizations['globalSettings'][lang]}</span>
        </div>
      </div> */}
      <div style={{ marginTop: '2rem' }}>
        <BlockSelector />
      </div>
      <div className="Functional-View-Switch-Area Custom-Scroll" style={{ maxHeight: 'calc(100% - 50px)' }}>
        <div className="mt-2">
          <h5 className="mb-1">{Localizations['pedestal'][lang]}</h5>
          <Selectbox state={socketStyle} setState={setSocketStyle} options={socketOptions} />
        </div>
      </div>
    </Fragment>
  );
};
