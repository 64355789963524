import * as BABYLON from 'babylonjs';

export default class CutOut {
  public l: number = 0;
  public r: number = 0;
  public t: number = 0;
  public b: number = 0;

  constructor(l?: number, r?: number, t?: number, b?: number) {
    this.l = l || 0;
    this.r = r || 0;
    this.t = t || 0;
    this.b = b || 0;
  }
}
