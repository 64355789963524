import BlockRowNode from 'components/babylon/node/BlockRowNode';
import { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import Block from './Block';
import BlockGroup from './BlockGroup';
import BlockItem from './BlockItem';

export default class BlockRow {
  public clazz = 'Row';
  private type: 'Top' | 'Bottom' = null;
  private depth: number = 700;
  private items: Array<BlockItem | BlockGroup> = new Array<BlockItem | BlockGroup>();

  private _node: BlockRowNode;
  private _parent: Block;

  constructor(type: 'Top' | 'Bottom', parent: Block, node: BlockRowNode) {
    this.type = type;
    this._parent = parent;
    this._node = node;
    switch (type) {
      case 'Top':
        this._parent.getNode().getTop().setRow(this);
        break;
      case 'Bottom':
        this._parent.getNode().getBottom().setRow(this);
        break;
    }
  }

  public getType() {
    return this.type;
  }

  public getWidth() {
    let width = 0;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      width += item.getWidth();
    }

    return width;
  }

  public getDepth() {
    return this.depth;
  }

  public getDepthExtended() {
    //const extendedFree = this.getType() === 'Bottom' && this.getBlock().getType() === 'Single' && this.getBlock().getSingleType() === 'Free' ? 40 : 0;
    return this.getDepth() + this.getExtendedSize();
  }

  public setDepth(depth: number) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (!item.compatibleWithDepth(depth)) return false;
    }
    this.depth = depth;
    this._parent.getNode().updateDimension();
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      item.getNode().set(DNPT.Depth, depth, true);
    }
    return true;
  }

  public getItems() {
    return this.items;
  }

  public addItem(e: Device | BlockItem | BlockGroup, index?: number): BlockItem | BlockGroup {
    let bi: BlockItem | BlockGroup = null;
    if (e instanceof BlockItem) {
      if (typeof index === 'number') this.items.splice(index, 0, e);
      else this.items.push(e);
      e.setParent(this);
      e.getNode().parent = this._node.getDeviceNode();
      e.getNode().set(DNPT.Depth, this.depth, true);

      this._specialAddItemCheck(e);

      bi = e;
    } else if (e instanceof BlockGroup) {
      const revert = e.getParent() !== this;
      if (typeof index === 'number') this.items.splice(index, 0, e);
      else this.items.push(e);
      e.setParent(this);
      e.getNode().parent = this._node.getDeviceNode();
      e.getNode().set(DNPT.Depth, this.depth, true);
      e.getNode().setAll(DNPT.Left, false);
      e.getNode().setAll(DNPT.Right, false);
      e.getNode().setAll(DNPT.HandleLeft, false);
      e.getNode().setAll(DNPT.HandleRight, false);

      for (let i = 0; i < e.getItems().length; i++) {
        const item = e.getItems()[i];
        this.removeItem(item, true);

        this._specialAddItemCheck(item);
      }

      if (revert) e.revertItems();
      e.repositionItems();
      this.repositionItems();

      bi = e;
    } else {
      let x = 0;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        x += item.getWidth() / 10;
      }
      const item = new BlockItem(this, e);
      this.items.push(item);
      item.getNode().parent = this._node.getDeviceNode();
      item.getNode().setPosition({ x: x });
      this.repositionItems();

      this._specialAddItemCheck(item);

      bi = item;
    }
    bi.getNode().set(DNPT.Rotate, this.type === 'Top');
    this._node.updateUnusedSpaceLabel();
    this._parent.getNode().updateDimension();
    this._parent.checkFullBlendColor();
    return bi;
  }

  public _specialAddItemCheck(item: BlockItem) {
    item.setError(false);
    if (item.getDeviceObject().style.indexOf('exclude:SingleFree') >= 0 && this.getBlock().getType() === 'Single' && this.getBlock().getSingleType() === 'Free')
      item.setError(true);
    if (item.getDeviceObject().style.indexOf('exclude:SingleWall') >= 0 && this.getBlock().getType() === 'Single' && this.getBlock().getSingleType() === 'Wall')
      item.setError(true);
    if (item.getDeviceObject().style.indexOf('exclude:Double') >= 0 && this.getBlock().getType() === 'Double') item.setError(true);
  }

  public moveItemToPosition(item: BlockItem | BlockGroup, x: number) {
    const pos = this.guessItemPositionByX(item, x);
    // console.log('moveItemToPosition', pos);
    if (pos.from !== pos.to) {
      this.items.splice(pos.to, 0, this.items.splice(pos.from, 1)[0]);
    }

    this.repositionItems();
  }

  public guessItemPositionByX(item: BlockItem | BlockGroup, x: number): { from: number; to: number } {
    let newX = 0;
    for (let i = 0; i < this.items.length; i++) {
      const e = this.items[i];
      const width = e.getWidth() / 10;

      if (x > newX + width * 0.5 && i < this.items.length - 1) {
        newX += width;
      } else {
        if (e.getUniqueId() !== item.getUniqueId()) {
          // Move in Item Array
          let from = 0;
          for (; from < this.items.length; from++) {
            const p = this.items[from];
            if (p.getUniqueId() === item.getUniqueId()) {
              break;
            }
          }
          return {
            from: from,
            to: i
          };
        }
        break;
      }
    }

    let from = 0;
    for (; from < this.items.length; from++) {
      const p = this.items[from];
      if (p.getUniqueId() === item.getUniqueId()) {
        break;
      }
    }
    return {
      from: from,
      to: from
    };
  }

  public repositionItems(forceBake = false) {
    let newX = 0;
    const isBottom = this.type === 'Bottom';
    const extendedSize = this.getExtendedSize();
    for (let i = 0; i < this.items.length; i++) {
      // console.log('set', i, 'to', newX)
      const item = this.items[i];
      const width = item.getWidth() / 10;

      item.getNode().setPosition({ x: newX, y: 0, z: 0 });

      const isLeft = isBottom ? i < 1 : i >= this.items.length - 1;
      const isRight = isBottom ? i >= this.items.length - 1 : i < 1;
      let bake = false;

      if (isLeft && !item.getNode().get(DNPT.Left)) {
        item.getNode().set(DNPT.Left, true);
        bake = true;
      } else if (!isLeft && item.getNode().get(DNPT.Left)) {
        item.getNode().set(DNPT.Left, false);
        bake = true;
      }

      if (isRight && !item.getNode().get(DNPT.Right)) {
        item.getNode().set(DNPT.Right, true);
        bake = true;
      } else if (!isRight && item.getNode().get(DNPT.Right)) {
        item.getNode().set(DNPT.Right, false);
        bake = true;
      }

      const parentHandle = this._parent.getHandle() ? this._parent.getHandle().style : null;
      if (item instanceof BlockItem) {
        if (item.compatibleWithEquipment(Subtype.HandRail)) {
          {
            const handle = item.getNode().get(DNPT.Handle);
            if (handle !== parentHandle) {
              item.getNode().set(DNPT.Handle, parentHandle);
              bake = true;
            }
          }
          {
            // Check Left
            const condition = isLeft || !item.prev().compatibleWithEquipment(Subtype.HandRail);
            const state = item.getNode().get(DNPT.HandleLeft);
            if (condition && !state) {
              item.getNode().set(DNPT.HandleLeft, true);
              bake = true;
            } else if (!condition && state) {
              item.getNode().set(DNPT.HandleLeft, false);
              bake = true;
            }
          }
          {
            // Check Right
            const condition = isRight || !item.next().compatibleWithEquipment(Subtype.HandRail);
            const state = item.getNode().get(DNPT.HandleRight);
            if (condition && !state) {
              item.getNode().set(DNPT.HandleRight, true);
              bake = true;
            } else if (!condition && state) {
              item.getNode().set(DNPT.HandleRight, false);
              bake = true;
            }
          }
        }
        item.getNode().extended(extendedSize);
      } else {
        for (let i = 0; i < item.getItems().length; i++) {
          const subItem = item.getItems()[i];
          if (subItem.compatibleWithEquipment(Subtype.HandRail)) {
            const handle = subItem.getNode().get(DNPT.Handle);
            if (handle !== parentHandle) {
              subItem.getNode().set(DNPT.Handle, parentHandle);
              bake = true;
            }
          }
          subItem.getNode().extended(extendedSize);
        }
        if (item.getItems()[0].compatibleWithEquipment(Subtype.HandRail)) {
          // Check Left
          const condition = isLeft || !item.prev().compatibleWithEquipment(Subtype.HandRail);
          const state = item.getNode().get(DNPT.HandleLeft);
          if (condition && !state) {
            item.getNode().set(DNPT.HandleLeft, true);
            bake = true;
          } else if (!condition && state) {
            item.getNode().set(DNPT.HandleLeft, false);
            bake = true;
          }
        }
        if (item.getItems()[item.getItems().length - 1].compatibleWithEquipment(Subtype.HandRail)) {
          // Check Right
          const condition = isRight || !item.next().compatibleWithEquipment(Subtype.HandRail);
          const state = item.getNode().get(DNPT.HandleRight);
          if (condition && !state) {
            item.getNode().set(DNPT.HandleRight, true);
            bake = true;
          } else if (!condition && state) {
            item.getNode().set(DNPT.HandleRight, false);
            bake = true;
          }
        }
      }

      if (bake || forceBake) item.getNode().bake();

      newX += width;
    }
    if (this._parent.getBoard()) this._parent.getBoard().rerender();
  }

  public removeItem(item: BlockItem | BlockGroup, doNotReposition?: boolean) {
    let index = -1;
    for (let i = 0; i < this.items.length; i++) {
      const e = this.items[i];
      if (e.getUniqueId() === item.getUniqueId()) {
        index = i;
        break;
      }
    }
    if (index >= 0) this.items.splice(index, 1);

    if (!doNotReposition) this.repositionItems();
    this._node.updateUnusedSpaceLabel();
    this._parent.getNode().updateDimension();
  }

  public getWidthDeviceMap() {
    const map = new Map<number, Device>();
    let w = 0;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item instanceof BlockItem) {
        let x = 0;
        for (; x < item.getWidth(); x += 100) {
          map.set(w + x, item.getDeviceObject());
        }
        w += x;
      } else {
        for (let j = 0; j < item.getItems().length; j++) {
          const groupItem = item.getItems()[j];
          let x = 0;
          for (; x < groupItem.getWidth(); x += 100) {
            map.set(w + x, groupItem.getDeviceObject());
          }
          w += x;
        }
      }
    }
    return map;
  }

  public getExtendedSize() {
    let extendedSize = 0;
    if (this.getBlock().isMasterline()) {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (item instanceof BlockItem) {
          if (item.getDeviceObject().dependency.coverEnlargement && item.getDeviceObject().dependency.coverEnlargementSize > extendedSize) {
            extendedSize = item.getDeviceObject().dependency.coverEnlargementSize;
          }
        } else if (item instanceof BlockGroup) {
          for (let j = 0; j < item.getItems().length; j++) {
            const blockItem = item.getItems()[j];
            if (blockItem.getDeviceObject().dependency.coverEnlargement && blockItem.getDeviceObject().dependency.coverEnlargementSize > extendedSize) {
              extendedSize = blockItem.getDeviceObject().dependency.coverEnlargementSize;
            }
          }
        }
      }
    }
    return extendedSize;
  }

  public cleanResources() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      item.getNode().dispose();
    }
  }

  public getParent() {
    return this._parent;
  }

  public getBlock() {
    return this._parent;
  }

  public getNode() {
    return this._node;
  }

  /**
   * Returns the index of an item and the total size of items in this row.
   *
   * @param item
   * @returns [index, size]
   */
  public getItemPosition(item: BlockItem | BlockGroup): [number, number] {
    let index = -1;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].getUniqueId() === item.getUniqueId()) {
        index = i;
        break;
      }
    }
    if (this.type === 'Top' && index >= 0) index = this.items.length - 1 - index;
    return [index, this.items.length];
  }
}
