import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { EditorContext, LangContext, useUser } from 'context';

// Components
import { AEditorMenuItem, Button, Icon } from 'atoms';
import { LoadingSpinner } from 'components';
import Block from 'page/Editor/configuration/Block';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { OnEvent, Selected } from 'page/Editor/Scene';
import BlockBoard from '../configuration/BlockBoard';
import FreeItem from '../configuration/FreeItem';
import RoomDoor from '../configuration/RoomDoor';
import { RoomPillar } from '../configuration/RoomPillar';
import { RoomWall } from '../configuration/RoomWall';
import RoomWindow from '../configuration/RoomWindow';
import { BottomNav, SaveDialog } from './Shared';

// Languages:
import Localizations from 'languages';

// Partials:
import { FlexichefMenu, MarineMeister, Masterline, Modular, ModularNOL, RoomMenu, SpaceCombiMenu } from './Menus';

// Styles:
import '../Scene.scss';
import './style.scss';

// Types
import { SavedConfiguration } from 'types';

// Utilities
import { post } from 'utils';
import BlockGroup from '../configuration/BlockGroup';

// ===================================================================
export const Menu: FC = () => {
  const {
    configuration,
    savedConfiguration,
    canAdvanceMasterline,
    canAdvanceModular,
    canAdvanceModularNOL,
    marineMeisterView,
    masterlineView,
    modularView,
    modularNOLView,
    scene,
    selectedMasterline,
    selectedModular,
    selectedModularNOL,
    setSelectedModular,
    setSelectedModularNOL,
    setFullscreen,
    setMasterlineView,
    setCanAdvanceMasterline,
    setCanAdvanceModular,
    setCanAdvanceModularNOL,
    setSavedConfiguration,
    setSelectedMasterline,
    setSelectedMarineMeister,
    setRoomView,
    owner,
    fullscreen,
    selection,
    setSelection,
    menuView,
    setMarineMeisterView,
    setMenuView,
    setModularView,
    setModularNOLView
  } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const block =
    menuView === 'modular' ? selectedModular : menuView === 'modularNOL' ? selectedModularNOL : menuView === 'masterline' ? selectedMasterline : null;
  const { hasRoomAccess, hasFlexiAccess, hasMarineMeisterAccess, hasMasterlineAccess, hasModularAccess, hasModularNOLAccess, hasSpaceAccess } = useUser();

  const [activePage, setActivePage] = useState({ title: '', number: 0, next: () => {}, prev: () => {} });
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean | 'success' | 'failure'>(false);

  useEffect(() => {
    if (menuView === 'masterline') {
      if (masterlineView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setMasterlineView('structure') });
      } else if (masterlineView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setMasterlineView('home'),
          next: () => setMasterlineView('functional')
        });
      } else if (masterlineView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setMasterlineView('structure'),
          next: () => setMasterlineView('upperStructures')
        });
      } else if (masterlineView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setMasterlineView('functional'),
          next: () => setMasterlineView('substructures')
        });
      } else if (masterlineView === 'substructures') {
        setActivePage({
          title: Localizations['substructureOptions'][lang],
          number: 4,
          prev: () => setMasterlineView('upperStructures'),
          next: () => setMasterlineView('global')
        });
      } else if (masterlineView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setMasterlineView('substructures'), next: null });
      }
    } else if (menuView === 'modular') {
      if (modularView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setModularView('structure') });
      } else if (modularView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setModularView('home'),
          next: () => setModularView('functional')
        });
      } else if (modularView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setModularView('structure'),
          next: () => setModularView('upperStructures')
        });
      } else if (modularView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setModularView('functional'),
          next: () => setModularView('substructures')
        });
      } else if (modularView === 'substructures') {
        setActivePage({
          title: Localizations['substructureOptions'][lang],
          number: 4,
          prev: () => setModularView('upperStructures'),
          next: () => setModularView('global')
        });
      } else if (modularView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setModularView('substructures'), next: null });
      }
    } else if (menuView === 'modularNOL') {
      if (modularNOLView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setModularNOLView('structure') });
      } else if (modularNOLView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setModularNOLView('home'),
          next: () => setModularNOLView('functional')
        });
      } else if (modularNOLView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setModularNOLView('structure'),
          next: () => setModularNOLView('upperStructures')
        });
      } else if (modularNOLView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setModularNOLView('functional'),
          next: () => setModularNOLView('substructures')
        });
      } else if (modularNOLView === 'substructures') {
        setActivePage({
          title: Localizations['substructureOptions'][lang],
          number: 4,
          prev: () => setModularNOLView('upperStructures'),
          next: () => setModularNOLView('global')
        });
      } else if (modularNOLView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setModularNOLView('substructures'), next: null });
      }
    } else if (menuView === 'marineMeister') {
      if (marineMeisterView === 'home') {
        setActivePage({ title: Localizations['startPage'][lang], number: 0, prev: null, next: () => setMarineMeisterView('structure') });
      } else if (marineMeisterView === 'structure') {
        setActivePage({
          title: Localizations['structure'][lang],
          number: 1,
          prev: () => setMarineMeisterView('home'),
          next: () => setMarineMeisterView('functional')
        });
      } else if (marineMeisterView === 'functional') {
        setActivePage({
          title: Localizations['functionalDevices'][lang],
          number: 2,
          prev: () => setMarineMeisterView('structure'),
          next: () => setMarineMeisterView('upperStructures')
        });
      } else if (marineMeisterView === 'upperStructures') {
        setActivePage({
          title: Localizations['upperStructures'][lang],
          number: 3,
          prev: () => setMarineMeisterView('functional'),
          next: () => setMarineMeisterView('global')
        });
      } else if (marineMeisterView === 'global') {
        setActivePage({ title: Localizations['globalSettings'][lang], number: 5, prev: () => setMarineMeisterView('upperStructures'), next: null });
      }
    }
  }, [masterlineView, marineMeisterView, menuView, modularView, modularNOLView, lang]);

  useEffect(() => {
    if (block) {
      if (menuView === 'masterline' && (masterlineView === 'home' || masterlineView === 'structure')) {
        setCanAdvanceMasterline(true);
      } else {
        if (
          block &&
          ((block.getRowBottom() && block.getRowBottom().getItems().length > 0) ||
            (block.getRowTop() && block.getRowTop().getItems() && block.getRowTop().getItems().length > 0))
        ) {
          setCanAdvanceMasterline(true);
        } else {
          setCanAdvanceMasterline(false);
        }
      }
    } else {
      if (menuView === 'masterline') setCanAdvanceMasterline(false);
      if (menuView === 'modular') setCanAdvanceModular(false);
      if (menuView === 'modularNOL') setCanAdvanceModularNOL(false);
    }
  }, [block, canAdvanceMasterline, masterlineView, menuView, modularView, modularNOLView, selection]);

  useEffect(() => {
    if (configuration && configuration.getModular() && configuration.getModular().length > 0) {
      if (menuView === 'modular' && (modularView === 'home' || modularView === 'structure')) {
        setCanAdvanceModular(true);
      } else {
        const containsDevices = configuration.getModular().findIndex((b: Block) => {
          return (
            (b.getRowBottom() && b.getRowBottom().getItems() && b.getRowBottom().getItems().length > 0) ||
            (b.getRowTop() && b.getRowTop().getItems() && b.getRowTop().getItems().length > 0)
          );
        });
        setCanAdvanceModular(containsDevices !== -1);
      }
    }
  }, [configuration, canAdvanceModular, menuView, modularView]);

  useEffect(() => {
    if (configuration && configuration.getModularNOL() && configuration.getModularNOL().length > 0) {
      if (menuView === 'modularNOL' && (modularNOLView === 'home' || modularNOLView === 'structure')) {
        setCanAdvanceModularNOL(true);
      } else {
        const containsDevices = configuration.getModularNOL().findIndex((b: Block) => {
          return (
            (b.getRowBottom() && b.getRowBottom().getItems() && b.getRowBottom().getItems().length > 0) ||
            (b.getRowTop() && b.getRowTop().getItems() && b.getRowTop().getItems().length > 0)
          );
        });
        setCanAdvanceModularNOL(containsDevices !== -1);
      }
    }
  }, [configuration, canAdvanceModularNOL, menuView, modularNOLView]);

  const adaptMenuViewToSelection = (data: Selected) => {
    if (data && data instanceof BlockItem) {
      const blockType = data?.getParent()?.getBlock()?.getBlockType();

      if (blockType === 'Masterline') {
        if (hasMasterlineAccess) setMenuView('masterline');
        else setMenuView(null);
      } else if (blockType === 'MarineMeister') {
        if (hasMarineMeisterAccess) {
          setMenuView('marineMeister');
        } else setMenuView(null);
      } else if (blockType === 'ModularNOL') {
        if (hasModularNOLAccess) {
          setMenuView('modularNOL');
        } else setMenuView(null);
      } else {
        if (hasModularAccess) setMenuView('modular');
        else setMenuView(null);
      }
    }
    if (data && data instanceof BlockGroup) {
      setSelection(null);
      setSelection(data);
      const blockType = data?.getParent()?.getBlock()?.getBlockType();
      if (blockType === 'Masterline') {
        if (hasMasterlineAccess) setMenuView('masterline');
        else setMenuView(null);
      } else if (blockType === 'Modular') {
        if (hasModularAccess) setMenuView('modular');
        else setMenuView(null);
      } else if (blockType === 'ModularNOL') {
        if (hasModularNOLAccess) setMenuView('modularNOL');
        else setMenuView(null);
      }
    }
    if (data && data instanceof Block) {
      const blockType = data?.getBlockType();

      if (blockType === 'Masterline') {
        if (hasMasterlineAccess) {
          setMasterlineView('structure');
          setSelectedMasterline(data);
          setMenuView('masterline');
        } else setMenuView(null);
      } else if (blockType === 'MarineMeister') {
        if (hasMarineMeisterAccess) {
          setMarineMeisterView('structure');
          setSelectedMarineMeister(data);
          setMenuView('marineMeister');
        } else setMenuView(null);
      } else if (blockType === 'ModularNOL') {
        if (hasModularNOLAccess) {
          setModularNOLView('structure');
          setSelectedModularNOL(data);
          setMenuView('modularNOL');
        } else setMenuView(null);
      } else {
        if (hasModularAccess) {
          setModularView('structure');
          setSelectedModular(data);
          setMenuView('modular');
        } else setMenuView(null);
      }
    }
    if (data && data instanceof FreeItem) {
      const device = data.getDeviceObject();
      if (device && device.model) {
        if (device.model.flexiChef) {
          if (hasFlexiAccess) setMenuView('flexiChef');
          else setMenuView(null);
        } else if (device.model.spaceCombi) {
          if (hasSpaceAccess) setMenuView('spaceCombi');
          else setMenuView(null);
        }
      }
    }

    if (data && data instanceof RoomDoor && hasRoomAccess) {
      setMenuView('room');
      setRoomView('doors');
    }
    if (data && data instanceof RoomWindow && hasRoomAccess) {
      setMenuView('room');
      setRoomView('windows');
    }
    if (data && data instanceof RoomWall && hasRoomAccess) {
      setMenuView('room');
      setRoomView('walls');
    }
    if (data && data instanceof RoomPillar && hasRoomAccess) {
      setMenuView('room');
      setRoomView('pillars');
    }
    if (data && data instanceof BlockBoard) {
      if (hasMasterlineAccess) {
        setMenuView('masterline');
        setMasterlineView('shelfMenu');
        if (data.getParent()) {
          setSelectedMasterline(data.getParent());
        }
      } else setMenuView(null);
    }
  };

  useEffect(() => {
    const handleEvent = (event: OnEvent) => {
      const { type, data } = event;

      // Handle Selection of any kind
      if (type === 'select') {
        setSelection(data);
        adaptMenuViewToSelection(data);
      }
      // Handle Deselection
      if (type === 'deselect') {
        setSelection(null);
        scene.setMergeMode(false);
      }
      // Handle Grab
      if (type === 'grab' && data instanceof Block) {
        setSelection(null);
        setSelection(data);
      }
    };
    if (scene) {
      scene.pushOnEvent((evt: any) => handleEvent(evt));
    }
  }, [scene]);

  useEffect(() => {
    if (saving === 'success' || saving === 'failure')
      setTimeout(() => {
        setSaving(false);
      }, 1500);
  }, [saving]);

  useEffect(() => {
    if (fullscreen) {
      setFullscreen(null);
      setSelection(null);
    }
  }, [menuView]);

  const saveConfiguration = async () => {
    if (savedConfiguration && savedConfiguration.id !== '' && !saving && configuration) {
      setSaving(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/configuration/save`, {
        data: {
          id: savedConfiguration.id ? savedConfiguration.id : null,
          customer: savedConfiguration.customer,
          location: savedConfiguration.location,
          name: savedConfiguration.name,
          public: savedConfiguration.public,
          json: configuration.exportJSON()
        }
      });

      setTimeout(() => {
        if (data) {
          if (!savedConfiguration.id) {
            const newConfig: SavedConfiguration = {
              id: data.id,
              customer: data.customer,
              name: data.name,
              public: data.public,
              json: data.json,
              created: data.created,
              email: data.email,
              location: data.location
            };
            setSavedConfiguration(newConfig);
          }
          history.push(`/editor?id=${data.id}`);

          setSaving('success');
        }
        if (error) {
          console.log('Error saving the Config:', error);
          setSaving('failure');
        }
      }, 1000);
    } else setSaving('failure');
  };

  const continueInMasterline = () => {
    if (masterlineView === 'home') setMasterlineView('structure');
    else if (masterlineView === 'structure') setMasterlineView('functional');
    else if (masterlineView === 'functional') setMasterlineView('upperStructures');
    else if (masterlineView === 'upperStructures') setMasterlineView('substructures');
    else if (masterlineView === 'substructures') setMasterlineView('global');
    else if (masterlineView === 'global') setMasterlineView('home');
  };

  const continueInModular = () => {
    if (modularView === 'home') setModularView('structure');
    else if (modularView === 'structure') setModularView('functional');
    else if (modularView === 'functional') setModularView('upperStructures');
    else if (modularView === 'upperStructures') setModularView('substructures');
    else if (modularView === 'substructures') setModularView('global');
    else if (modularView === 'global') setModularView('home');
  };

  const continueInModularNOL = () => {
    if (modularNOLView === 'home') setModularNOLView('structure');
    else if (modularNOLView === 'structure') setModularNOLView('functional');
    else if (modularNOLView === 'functional') setModularNOLView('upperStructures');
    else if (modularNOLView === 'upperStructures') setModularNOLView('substructures');
    else if (modularNOLView === 'substructures') setModularNOLView('global');
    else if (modularNOLView === 'global') setModularNOLView('home');
  };

  return (
    <Fragment>
      {/* Menu */}
      <div className="Scene-Menu">
        <div className="Scene-Menu-Content">
          {menuView === 'room' && (
            <AEditorMenuItem key="scene-menu-room">
              <RoomMenu />
            </AEditorMenuItem>
          )}

          {menuView === 'flexiChef' && (
            <AEditorMenuItem key="scene-menu-flexichef">
              <FlexichefMenu />
            </AEditorMenuItem>
          )}

          {menuView === 'spaceCombi' && (
            <AEditorMenuItem key="scene-menu-spacecombi">
              <SpaceCombiMenu />
            </AEditorMenuItem>
          )}

          {menuView === 'modular' && (
            <AEditorMenuItem key="scene-menu-modular">
              <Modular />
            </AEditorMenuItem>
          )}

          {menuView === 'modularNOL' && (
            <AEditorMenuItem key="scene-menu-modular-new">
              <ModularNOL />
            </AEditorMenuItem>
          )}

          {menuView === 'masterline' && (
            <AEditorMenuItem key="scene-menu-masterline">
              <Masterline />
            </AEditorMenuItem>
          )}

          {menuView === 'marineMeister' && (
            <AEditorMenuItem key="scene-menu-marine">
              <MarineMeister />
            </AEditorMenuItem>
          )}
        </div>

        {/* Bottom */}
        {!fullscreen && (
          <div className="Scene-Menu-Bottom">
            <div className="w-100">
              {((menuView === 'masterline' && masterlineView !== 'home') ||
                (menuView === 'modular' && modularView !== 'home') ||
                (menuView === 'modularNOL' && modularNOLView !== 'home') ||
                (menuView === 'marineMeister' && marineMeisterView !== 'home')) && <BottomNav activePage={activePage} setActivePage={setActivePage} />}

              <div className="flex text-center" style={{ flex: 1 }}>
                <div className="flex" style={{ width: '52%' }}>
                  <Button btnType="third" disabled={!owner} margin="10px 1px 5px 0" padding="0.5rem" fontSize=".6rem" onClick={saveConfiguration} width="77%">
                    {!saving && Localizations['save'][lang]}
                    {saving && saving !== 'success' && saving !== 'failure' && <LoadingSpinner size="0.75rem" borderWidth={1} color="medium" />}
                    {saving && saving === 'success' && <Icon type="check" size="0.75rem" color="medium" />}
                    {saving && saving !== 'success' && saving === 'failure' && <Icon type="close" size="0.75rem" color="medium" />}
                  </Button>
                  <Button btnType="third" margin="10px 0 5px 0" onClick={() => setShowSaveDialog(true)} padding="0 0 3px 0" width="23%">
                    <Icon type="plus" size=".75rem" style={{ cursor: 'pointer' }} />
                  </Button>
                </div>
                <Button
                  btnType="third"
                  margin="10px 0 5px 0.65rem"
                  disabled={
                    (menuView === 'modular' &&
                      ((modularView === 'structure' &&
                        (!configuration ||
                          (configuration && !configuration.getModular()) ||
                          (configuration.getModular() && !(configuration.getModular().length > 0)))) ||
                        !canAdvanceModular)) ||
                    (menuView === 'modularNOL' &&
                      ((modularNOLView === 'structure' &&
                        (!configuration ||
                          (configuration && !configuration.getModularNOL()) ||
                          (configuration.getModularNOL() && !(configuration.getModularNOL().length > 0)))) ||
                        !canAdvanceModularNOL)) ||
                    (menuView === 'masterline' && !canAdvanceMasterline) ||
                    !configuration ||
                    menuView === 'room' ||
                    menuView === 'flexiChef' ||
                    menuView === 'spaceCombi'
                  }
                  padding=".5rem "
                  fontSize=".6rem"
                  onClick={() => {
                    setSelection(null);
                    scene.setSelected(null);
                    if (menuView === 'masterline') {
                      continueInMasterline();
                    } else if (menuView === 'modular') {
                      continueInModular();
                    } else if (menuView === 'modularNOL') {
                      continueInModularNOL();
                    }
                  }}
                  width="48%"
                >
                  {Localizations['continue'][lang]}
                </Button>
                {/* <Icon type="share" size="1.5em" color="medium" stroke={3} onClick={() => setShowDialog(true)} /> */}
              </div>
            </div>
          </div>
        )}
      </div>

      {configuration && <SaveDialog close={() => setShowSaveDialog(false)} open={showSaveDialog} />}
    </Fragment>
  );
};
