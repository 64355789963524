import React, { FC, useContext, Fragment, useEffect, useState } from 'react';

// Styles:
import { colors } from 'styles/theme';

// Components
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { Device, Subtype } from 'types';

// Utils
import { post } from 'utils';
import { Selectbox } from 'atoms';
import { isSubtype } from 'utils/subtype';
import { usePremiumKnobs } from 'page/Editor/Menu/Menus/ModularNOL/Partials/UpperStructures/usePremiumKnobs';

// ===================================================================
const existingSubtypes = ['UpperDrawer', 'UpperPowerSocket', 'MixingFaucet', 'PremiumKnob'];

export const ModularNOLUpperStructure: FC = () => {
  const { lang, langId } = useContext(LangContext);

  const { scene, selection, setModularNOLView, setSelection } = useContext(EditorContext);
  const [mergeMode, setMergeMode] = useState(false);
  const [selectionType, setSelectionType] = useState<'BlockItem' | 'BlockGroup'>(null);

  const [availableSubtypes, setAvailableSubtypes] = useState<Subtype[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [usedDevices, setUsedDevices] = useState({
    UpperDrawer: false,
    MixingFaucet: false,
    PremiumKnob: false
  });
  const { onAddPremiumKnobs, onRemovePremiumKnobs } = usePremiumKnobs({ selection, availableDevices });

  const [selectedUpperPowerSocket, setSelectedUpperPowerSocket] = useState(Localizations['none'][lang]);
  const [selectedUpperPowerSocket_Second, setSelectedUpperPowerSocket_Second] = useState(Localizations['none'][lang]);

  const getAvailableSockets = () => {
    return availableDevices.filter(d => isSubtype(d.subtype, ['UpperPowerSocket'])).map(d => d.translations[langId]?.name);
  };
  const availableSockets = getAvailableSockets();

  useEffect(() => {
    if (scene) {
      scene.setUpperStructureMode(true);
      if (scene.getSelected()) {
        setSelection(scene.getSelected());
      }
    }

    return () => {
      if (scene) {
        scene.setUpperStructureMode(false);
      }
      setSelection(null);
    };
  }, []);

  useEffect(() => {
    const width = selection instanceof BlockItem ? selection.getWidth() : selection instanceof BlockGroup ? selection.getWidth() : 0;
    const depth = selection instanceof BlockItem ? selection.getBlockRow().getDepth() : selection instanceof BlockGroup ? selection.getParent().getDepth() : 0;
    const checkAvailableSubtypes = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'modularNOL',
          width: width,
          depth: depth,
          subtypes: existingSubtypes
        }
      });
      if (data) {
        console.log({ data });
        // Subtypes
        const tempAvailableSubtypes: Subtype[] = [];
        const tempAvailableDevices: Device[] = [];
        data.forEach((result: Device) => {
          //@ts-ignore
          if (!tempAvailableSubtypes.includes(result.subtype) && selection.compatibleWithEquipment(Subtype[result.subtype])) {
            //@ts-ignore
            tempAvailableSubtypes.push(Subtype[result.subtype]);
            tempAvailableDevices.push(result);
          }
        });
        setAvailableDevices(tempAvailableDevices);
        setAvailableSubtypes(tempAvailableSubtypes);
      }
      if (error) {
        console.log(error);
      }
    };

    if (!selection) {
      setMergeMode(false);
      setSelectionType(null);
    } else {
      if (selection instanceof BlockItem) {
        setSelectionType('BlockItem');
        checkAvailableSubtypes();
        setUsedDevices({
          PremiumKnob: EquipmentHelper.getPremiumKnob(selection) ? true : false,
          UpperDrawer: EquipmentHelper.getUpperDrawer(selection) ? true : false,
          MixingFaucet: EquipmentHelper.getMixingFaucet(selection) ? true : false
        });
        const firstSocket = EquipmentHelper.getUpperPowerSocket(selection);
        setSelectedUpperPowerSocket(firstSocket ? firstSocket.getDeviceObject()?.translations[langId]?.name : Localizations['none'][lang]);
        const secondSocket = EquipmentHelper.getUpperPowerSocket(selection, 1);
        setSelectedUpperPowerSocket_Second(secondSocket ? secondSocket.getDeviceObject()?.translations[langId]?.name : Localizations['none'][lang]);
      }
    }
  }, [selection]);

  return (
    <Fragment>
      <ContextMenu
        mode="back"
        onClick={() => {
          setModularNOLView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />

      {selectionType && !(selectionType === 'BlockItem' && mergeMode) && availableSubtypes.length > 1 && (
        <div
          className="text-xs bold uppercase mb-1"
          style={{
            color: colors['greyText']
          }}
        >
          {Localizations['specialEquipment'][lang]}
        </div>
      )}

      {!selection && <div className="mt-4 text-sm bold">{Localizations['pickATable'][lang]}</div>}
      {selection && availableSubtypes.length < 1 && <div className="mt-4 text-sm bold">{Localizations['noUpperDevices'][lang]}</div>}
      {selection && selection instanceof BlockItem && (
        <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 80px)' }}>
          {availableSubtypes.includes(Subtype.UpperDrawer) && (
            <>
              <EquipmentSwitch
                disabled={!EquipmentHelper.canHaveUpperDrawer(selection)}
                description={
                  //@ts-ignore
                  availableDevices.find(d => d.subtype === 'UpperDrawer')
                    ? //@ts-ignore
                      availableDevices.find(d => d.subtype === 'UpperDrawer').translations[langId].name
                    : ''
                }
                status={usedDevices.UpperDrawer}
                label={Localizations['upperDrawer'][lang]}
                toggleStatus={() => {
                  if (!usedDevices.UpperDrawer) {
                    EquipmentHelper.setUpperDrawer(
                      selection,
                      //@ts-ignore
                      availableDevices.find(d => d.subtype === 'UpperDrawer')
                    );
                  } else {
                    EquipmentHelper.setUpperDrawer(selection, null);
                  }
                  setUsedDevices({ ...usedDevices, UpperDrawer: !usedDevices.UpperDrawer });
                }}
                type="first"
              />
            </>
          )}
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <Selectbox
              label={'1. ' + Localizations['upperPowerSocket'][lang]}
              disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection)}
              options={[Localizations['none'][lang], ...availableSockets]}
              state={selectedUpperPowerSocket}
              setState={(value: string) => {
                EquipmentHelper.setUpperPowerSocket(
                  selection,
                  availableDevices.find((s: Device) => s.translations[langId].name === value)
                );
                setSelectedUpperPowerSocket(value);
              }}
            />
          )}
          {availableSubtypes.includes(Subtype.UpperPowerSocket) && (
            <div className="mt-1">
              <Selectbox
                label={'2. ' + Localizations['upperPowerSocket'][lang]}
                disabled={!EquipmentHelper.canHaveUpperPowerSocket(selection, 1)}
                options={[Localizations['none'][lang], ...availableSockets]}
                state={selectedUpperPowerSocket_Second}
                setState={(value: string) => {
                  EquipmentHelper.setUpperPowerSocket(
                    selection,
                    availableDevices.find((s: Device) => s?.translations[langId].name === value),
                    1
                  );
                  setSelectedUpperPowerSocket_Second(value);
                }}
              />
            </div>
          )}
          {availableSubtypes.includes(Subtype.MixingFaucet) && (
            <EquipmentSwitch
              disabled={!EquipmentHelper.canHaveMixingFaucet(selection)}
              description={
                //@ts-ignore
                availableDevices.find(d => d.subtype === 'MixingFaucet')
                  ? availableDevices.find(
                      //@ts-ignore
                      d => d.subtype === 'MixingFaucet'
                    ).translations[langId].name
                  : ''
              }
              status={usedDevices.MixingFaucet}
              label={Localizations['faucet'][lang]}
              toggleStatus={() => {
                if (!usedDevices.MixingFaucet) {
                  EquipmentHelper.setMixingFaucet(
                    selection,
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'MixingFaucet')
                  );
                } else {
                  EquipmentHelper.setMixingFaucet(selection, null);
                }
                setUsedDevices({ ...usedDevices, MixingFaucet: !usedDevices.MixingFaucet });
              }}
              type="first"
            />
          )}
          {availableSubtypes.includes(Subtype.PremiumKnob) && (
            <>
              <EquipmentSwitch
                disabled={!EquipmentHelper.canHavePremiumKnob(selection)}
                status={usedDevices.PremiumKnob}
                label={Localizations['premiumKnob'][lang]}
                toggleStatus={() => {
                  if (!usedDevices.PremiumKnob) {
                    onAddPremiumKnobs();
                  } else {
                    onRemovePremiumKnobs();
                  }
                  setUsedDevices({ ...usedDevices, PremiumKnob: !usedDevices.PremiumKnob });
                }}
                type="first"
              />
            </>
          )}
        </div>
      )}
    </Fragment>
  );
};
