import React from 'react';

type Props = {
  className: string;
  isDisabled?: boolean;
  label?: string;
  labelClassName?: string;
  onChange?: (e: number) => void;
  min: number;
  max: number;
  step: number;
  validateOnBlur?: boolean;
  value: number;
};

export const NumericInput: React.FC<Props> = ({ className, isDisabled, min, max, step, label, labelClassName, onChange, value, validateOnBlur }) => {
  const handleValidation = (v: number) => {
    if (v < min) onChange(Number(min));
    if (v > max) onChange(Number(max));
    if (v % 10 !== 0) {
      const rest = v % 10;
      if (rest < 5) {
        onChange(Number(v - rest));
      } else {
        onChange(Number(v + (10 - rest)));
      }
    }
  };

  return (
    <label className={labelClassName}>
      <input
        className={className}
        disabled={isDisabled}
        min={min}
        max={max}
        onBlur={e => {
          if (validateOnBlur) {
            handleValidation(Number(e.target.value));
          }
        }}
        onChange={e => onChange(Number(e.target.value))}
        step={step}
        type="number"
        value={value}
      />
      {label}
    </label>
  );
};
